import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/fieldset/code",
  "title": "Fieldset - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
  <Fieldset heading="Child information" style={{ maxWidth: '400px' }}>
    <TextInput id="first-name-child" name="first-name" label="First name" required />
    <TextInput id="last-name-child" name="last-name" label="Last name" required style={{ marginTop: '12px' }} />
    <TextInput
      id="social-security-number-child"
      name="social-security-number"
      label="Social security number"
      placeholder="Eg. 111299-1234"
      required
      style={{ marginTop: '12px' }}
    />
  </Fieldset>

  <Fieldset heading="Guardian information" style={{ maxWidth: '400px', marginTop: 'var(--spacing-s)' }}>
    <TextInput id="first-name-guardian" name="first-name" label="First name" required />
    <TextInput id="last-name-guardian" name="last-name" label="Last name" required style={{ marginTop: '12px' }} />
    <TextInput
      id="social-security-number-guardian"
      name="social-security-number"
      label="Social security number"
      placeholder="Eg. 111299-1234"
      required
      style={{ marginTop: '12px' }}
    />
  </Fieldset>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <fieldset class="hds-fieldset" style="max-width: 400px">
    <legend class="hds-fieldset-legend">Child information</legend>
    <div class="hds-text-input">
      <label for="first-name-child-1" class="hds-text-input__label">
        First name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="first-name-child-1" name="first-name-child-1" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="last-name-child-1" class="hds-text-input__label">
        Last name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="last-name-child-1" name="last-name-child-1" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="social-security-number-child-1" class="hds-text-input__label">
        Social security number
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input
          id="social-security-number-child-1"
          name="social-security-number-child-1"
          class="hds-text-input__input"
          type="text"
          placeholder="Eg. 111299-1234"
        />
      </div>
    </div>
  </fieldset>
  <fieldset class="hds-fieldset" style="max-width: 400px;margin-top: var(--spacing-s);">
    <legend class="hds-fieldset-legend">Guardian information</legend>
    <div class="hds-text-input">
      <label for="first-name-guardian-1" class="hds-text-input__label">
        First name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="first-name-guardian-1" name="first-name-guardian-1" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="last-name-guardian-1" class="hds-text-input__label">
        Last name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="last-name-guardian-1" name="last-name-guardian-1" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="social-security-number-guardian-1" class="hds-text-input__label">
        Social security number
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input
          id="social-security-number-guardian-1"
          name="social-security-number-guardian-1"
          class="hds-text-input__input"
          type="text"
        />
      </div>
    </div>
  </fieldset>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "with-border",
      "style": {
        "position": "relative"
      }
    }}>{`With border`}<a parentName="h3" {...{
        "href": "#with-border",
        "aria-label": "with border permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<>
  <Fieldset heading="Child information" border style={{ maxWidth: '400px' }}>
    <TextInput id="first-name-child-2" name="first-name" label="First name" required />
    <TextInput id="last-name-child-2" name="last-name" label="Last name" required style={{ marginTop: '12px' }} />
    <TextInput
      id="social-security-number-child-2"
      name="social-security-number-child-2"
      label="Social security number"
      placeholder="Eg. 111299-1234"
      required
      style={{ marginTop: '12px' }}
    />
  </Fieldset>

  <Fieldset heading="Guardian information" border style={{ maxWidth: '400px', marginTop: 'var(--spacing-s)' }}>
    <TextInput id="first-name-guardian-2" name="first-name" label="First name" required />
    <TextInput id="last-name-guardian-2" name="last-name" label="Last name" required style={{ marginTop: '12px' }} />
    <TextInput
      id="social-security-number-guardian-2"
      name="social-security-number-guardian-"
      label="Social security number"
      placeholder="Eg. 111299-1234"
      required
      style={{ marginTop: '12px' }}
    />
  </Fieldset>
</>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div>
  <fieldset class="hds-fieldset hds-fieldset--border" style="max-width: 400px;">
    <legend class="hds-fieldset-legend">Child information</legend>
    <div class="hds-text-input">
      <label for="first-name-child-3" class="hds-text-input__label">
        First name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="first-name-child-3" name="first-name-child-3" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="last-name-child-3" class="hds-text-input__label">
        Last name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="last-name-child-3" name="last-name-child-3" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="social-security-number-child-3" class="hds-text-input__label">
        Social security number
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input
          id="social-security-number-child-3"
          name="social-security-number-child-3"
          class="hds-text-input__input"
          type="text"
          placeholder="Eg. 111299-1234"
        />
      </div>
    </div>
  </fieldset>
  <fieldset class="hds-fieldset hds-fieldset--border" style="max-width: 400px;margin-top: var(--spacing-s);">
    <legend class="hds-fieldset-legend">Guardian information</legend>
    <div class="hds-text-input">
      <label for="first-name-guardian-3" class="hds-text-input__label">
        First name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="first-name-guardian-3" name="first-name-guardian-3" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="last-name-guardian-3" class="hds-text-input__label">
        Last name
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input id="last-name-guardian-3" name="last-name-guardian-3" class="hds-text-input__input" type="text" />
      </div>
    </div>
    <div class="hds-text-input" style="margin-top: 12px">
      <label for="social-security-number-guardian-3" class="hds-text-input__label">
        Social security number
        <span class="hds-text-input__required">*</span>
      </label>
      <div class="hds-text-input__input-wrapper">
        <input
          id="social-security-number-guardian-3"
          name="social-security-number-guardian-3"
          class="hds-text-input__input"
          type="text"
          placeholder="Eg. 111299-1234"
        />
      </div>
    </div>
  </fieldset>
</div>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-fieldset--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/fieldset/Fieldset.tsx" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-fieldset--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/fieldset" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-dropdowns-select--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`heading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading of the fieldset element.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, a border is drawn around the fieldset.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      